import { createRouter, createWebHistory } from "vue-router";

import main from "./pages/mainPage.vue";

const routes = [
	{
		path: "/",
		component: main,
		name: "form",
		meta: {
			pageTitle: "CLA Form",
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.pageTitle;
	next();
});

export default router;
