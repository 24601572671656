<template>
	<div class="common-layout">
		<el-container style="height: 100%">
			<el-main style="margin: 0px; padding: 0px">
				<div class="formWrapper">
					<el-card class="form" style="max-width: 600px">
						<img class="logo" :src="logo" />
						<el-form
							:label-position="labelPosition"
							label-width="auto"
							:model="form"
							style="max-width: 600px"
						>
							<h1 style="margin-top: 0px">About You</h1>
							<el-form-item label="Your full name" :label-position="itemLabelPosition">
								<el-input
									style="margin-top: 0px"
									v-model="form.fullName"
									:rows="2"
									type="textarea"
									placeholder="Input your full name"
								/>
							</el-form-item>
							<el-form-item label="Your date of birth" :label-position="itemLabelPosition">
								<el-date-picker
									v-model="form.dateOfBirth"
									type="date"
									range-separator="To"
									size="large"
								/>
							</el-form-item>
							<el-form-item label="Your current address & postcode" :label-position="itemLabelPosition">
								<el-input
									style="margin-top: 0px"
									v-model="form.currentAddressAndPostCode"
									:rows="4"
									type="textarea"
									placeholder="Input your current address & postcode"
								/>
							</el-form-item>
							<el-form-item
								label="Is your address the same as when you took out the motor finance policy/ policies?"
								:label-position="itemLabelPosition"
							>
								<el-radio-group v-model="form.currentAddressSameAsWhenFinanceWasTaken">
									<el-radio label="yes" size="large" border>Yes</el-radio>
									<el-radio label="no" size="large" border>No</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item
								label="Did you have more than one finance agreement?"
								:label-position="itemLabelPosition"
							>
								<el-radio-group v-model="form.multipleAgreements">
									<el-radio label="yes" size="large" border>Yes</el-radio>
									<el-radio label="no" size="large" border>No</el-radio>
								</el-radio-group>
							</el-form-item>
							<h1>Finance Provider</h1>
							<el-form-item
								label="Who provided your motor finance? This is the firm who supplied your finance agreement and you paid, NOT the car dealer or broker you got the vehicle from"
								:label-position="itemLabelPosition"
							>
								<el-select
									v-if="form.multipleAgreements === 'no'"
									v-model="form.individualFinanceProvider"
									placeholder="Select your finance provider"
									style="width: 240px"
									clearable
								>
									<template #label="{ label, value }">
										<span>{{ label }}: </span>
										<span style="font-weight: bold">{{ value }}</span>
									</template>
									<el-option
										v-for="item in financeProviders"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									/>
								</el-select>
								<el-select
									@change="handleChange"
									v-else
									v-model="form.multipleFinanceProviders"
									placeholder="Select all finance providers"
									style="width: 240px"
									clearable
									multiple
								>
									<template #label="{ label, value }">
										<span>{{ label }}: </span>
										<span style="font-weight: bold">{{ value }}</span>
									</template>
									<el-option
										v-for="item in financeProviders"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									/>
								</el-select>
							</el-form-item>
							<span
								v-if="
									(form.multipleAgreements === 'yes' &&
										form.multipleFinanceProvidersFullDetails.length) ||
									(form.multipleAgreements === 'no' && form.individualFinanceProvider)
								"
							>
								<h1>About Your Agreements</h1>
								<span v-if="form.multipleAgreements === 'yes'">
									<el-form-item
										:key="provider"
										v-for="provider in form.multipleFinanceProvidersFullDetails"
										:label="`With ${provider.provider}, do you have more than one agreement?`"
										:label-position="itemLabelPosition"
									>
										<el-radio-group v-model="provider.multipleAgreementsForProvider">
											<el-radio label="yes" size="large" border>Yes</el-radio>
											<el-radio label="no" size="large" border>No</el-radio>
										</el-radio-group>
										<p style="line-height: normal">
											{{
												provider.multipleAgreementsForProvider === "yes"
													? "Were the agreements"
													: "Was the agreement"
											}}
											taken out betwen 1997 and 2020?
										</p>
										<el-radio-group v-model="provider.agreementInValidYearRange">
											<el-radio label="yes" size="large" border>Yes</el-radio>
											<el-radio label="no" size="large" border>No</el-radio>
										</el-radio-group>
										<p style="line-height: normal">
											Please provide the total amount financed across agreements
										</p>
										<el-input
											style="margin-top: 0px"
											v-model="provider.totalAmountFinanced"
											type="number"
										/>
										<el-alert
											style="margin-top: 10px"
											:closable="false"
											v-if="provider.totalAmountFinanced > 0"
											:title="
												'Estimated claim value: ' +
												(provider.totalAmountFinanced * 0.41).toFixed(2)
											"
											type="info"
										/>
										<span v-if="form.currentAddressSameAsWhenFinanceWasTaken === 'no'">
											<p style="line-height: normal">
												Please provide your address and postcode at the time of taking out the
												agreement.
											</p>
											<el-input
												:rows="4"
												style="margin-top: 0px"
												v-model="provider.addressAtTimeOfAgreement"
												type="textarea"
												placeholder="Input your address and postcode at the time"
											/>
										</span>
										<span v-if="provider.multipleAgreementsForProvider === 'yes'">
											<p style="line-height: normal">
												Please provide any finance agreement policy numbers you have access to.
												These should be on all agreement letters or paperwork you have. Split
												numbers with a comma or put each on a new line
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="provider.knownPolicyNumbers"
												:rows="4"
												type="textarea"
												placeholder="Input your policy references/numbers"
											/>
											<p style="line-height: normal">
												Please provide the license plate numbers for the vehicles that were
												financed. Split numbers with a comma or put each on a new line
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="provider.licensePlateNumbers"
												:rows="4"
												type="textarea"
												placeholder="Input your license plate numbers, e.g. BD5I SMR"
											/>
										</span>
										<span v-if="provider.multipleAgreementsForProvider === 'no'">
											<p style="line-height: normal">
												Please provide the finance agreement policy number.
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="provider.knownPolicyNumbers"
												type="text"
												placeholder="Input your policy reference/number"
											/>
											<p style="line-height: normal">
												Please provide the license plate for the vehicle that was financed
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="provider.licensePlateNumbers"
												type="text"
												placeholder="Input your license plate number, e.g. BD5I SMR"
											/>
										</span>
										<el-divider />
									</el-form-item>
								</span>
								<span v-if="form.multipleAgreements === 'no'">
									<el-form-item
										:label="`With ${form.individualFinanceProvider}, do you have more than one agreement?`"
										:label-position="itemLabelPosition"
									>
										<el-radio-group v-model="form.multipleAgreementsForIndividualFinanceProvider">
											<el-radio label="yes" size="large" border>Yes</el-radio>
											<el-radio label="no" size="large" border>No</el-radio>
										</el-radio-group>
										<p style="line-height: normal">
											{{
												form.multipleAgreementsForIndividualFinanceProvider === "yes"
													? "Were the agreements"
													: "Was the agreement"
											}}
											taken out betwen 1997 and 2020?
										</p>
										<el-radio-group v-model="form.agreementInValidYearRange">
											<el-radio label="yes" size="large" border>Yes</el-radio>
											<el-radio label="no" size="large" border>No</el-radio>
										</el-radio-group>
										<p style="line-height: normal">
											Please provide the total amount financed across agreements
										</p>
										<el-input
											style="margin-top: 0px"
											v-model="form.totalAmountFinanced"
											type="number"
										/>
										<el-alert
											style="margin-top: 10px"
											:closable="false"
											v-if="form.totalAmountFinanced > 0"
											:title="
												'Estimated claim value: ' + (form.totalAmountFinanced * 0.41).toFixed(2)
											"
											type="info"
										/>
										<span v-if="form.currentAddressSameAsWhenFinanceWasTaken === 'no'">
											<p style="line-height: normal">
												Please provide your address and postcode at the time of taking out the
												agreement
											</p>
											<el-input
												:rows="4"
												style="margin-top: 0px"
												v-model="form.addressAtTimeOfFinanceAgreement"
												type="textarea"
												placeholder="Input your address and postcode at the time"
											/>
										</span>
										<span v-if="form.multipleAgreementsForIndividualFinanceProvider === 'no'">
											<p style="line-height: normal">
												Please provide the finance agreement policy number
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="form.policyNumber"
												type="text"
												placeholder="Input your policy reference/number"
											/>
											<p style="line-height: normal">
												Please provide the license plate for the vehicle that was financed
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="form.licensePlateNumber"
												type="text"
												placeholder="Input your license plate number, e.g. BD5I SMR"
											/>
										</span>
										<span v-if="form.multipleAgreementsForIndividualFinanceProvider === 'yes'">
											<p style="line-height: normal">
												Please provide any finance agreement policy numbers you have access to.
												These should be on all agreement letters or paperwork you have. Split
												numbers with a comma or put each on a new line
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="form.policyNumber"
												:rows="4"
												type="textarea"
												placeholder="Input your policy references/numbers"
											/>
											<p style="line-height: normal">
												Please provide the license plate numbers for the vehicles that were
												financed. Split numbers with a comma or put each on a new line
											</p>
											<el-input
												style="margin-top: 0px"
												v-model="form.licensePlateNumber"
												:rows="4"
												type="textarea"
												placeholder="Input your license plate numbers, e.g. BD5I SMR"
											/>
										</span>
									</el-form-item>
								</span>
							</span>
							<h1>Signature</h1>
							<p>
								I hereby authorise CLA International FZE to act as my exclusive agent with respect to
								the submission and management of all complaints against the referenced auto finance
								companies for the miss-selling of automotive finance
							</p>
							<VueSignaturePad
								id="signature"
								width="100%"
								height="200px"
								ref="signaturePad"
								:options="options"
							/>
							<el-button
								style="margin: auto; display: block; margin-top: 5px"
								@click="clearSignature"
								type="primary"
								text
							>
								Clear signature
							</el-button>
						</el-form>
						<el-button
							:disabled="!minimumDataAvailable"
							@click="generatePdf()"
							style="position: fixed; bottom: 0px; width: 100%; left: 0px; z-index: 10000"
							type="success"
						>
							Click To Generate Submission
						</el-button>
					</el-card>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import "regenerator-runtime/runtime";
import logo from "../assets/logo.png";
import { usePDF } from "vue3-pdfmake";
let generatePdfProxy;
export default {
	computed: {
		minimumDataAvailable() {
			if (this.form.fullName && this.form.currentAddressAndPostCode) {
				if (this.form.multipleAgreements === "yes") {
					if (this.form.multipleFinanceProvidersFullDetails.length) {
						return true;
					} else {
						return false;
					}
				} else {
					if (this.form.individualFinanceProvider) {
						return true;
					} else {
						return false;
					}
				}
			} else {
				return false;
			}
		},
	},
	beforeMount() {
		const pdfMake = usePDF({
			autoInstallVFS: true,
		});
		generatePdfProxy = pdfMake;
	},
	components: {},
	data() {
		return {
			options: { penColor: "#dc1e34" },
			logo: logo,
			financeProviders: [
				{ label: "Admiral", value: "Admiral" },
				{ label: "Advantage Finance", value: "Advantage Finance" },
				{ label: "Aldermore", value: "Aldermore" },
				{ label: "Alphera", value: "Alphera" },
				{ label: "Audi (VW Financial Services)", value: "Audi (VW Financial Services)" },
				{ label: "Autolend", value: "Autolend" },
				{ label: "Bamboo", value: "Bamboo" },
				{ label: "Barclays Partner Finance", value: "Barclays Partner Finance" },
				{ label: "Billing Finance", value: "Billing Finance" },
				{ label: "Black Horse", value: "Black Horse" },
				{ label: "Blue Motor Finance", value: "Blue Motor Finance" },
				{ label: "BMW Financial Services (inc. Mini)", value: "BMW Financial Services (inc. Mini)" },
				{ label: "CA Auto Finance", value: "CA Auto Finance" },
				{ label: "CarCashPoint", value: "CarCashPoint" },
				{ label: "CarFinance 247", value: "CarFinance 247" },
				{ label: "CarMoney", value: "CarMoney" },
				{ label: "Carmoola", value: "Carmoola" },
				{ label: "Close Brothers", value: "Close Brothers" },
				{ label: "FCA Automotive", value: "FCA Automotive" },
				{ label: "Finio Loans", value: "Finio Loans" },
				{ label: "First Response Finance", value: "First Response Finance" },
				{ label: "Ford Credit Europe (FCE)", value: "Ford Credit Europe (FCE)" },
				{ label: "Hitachi Capital (Novuna)", value: "Hitachi Capital (Novuna)" },
				{ label: "Honda Financial Services", value: "Honda Financial Services" },
				{ label: "Hyundai Finance", value: "Hyundai Finance" },
				{ label: "JBR Capital", value: "JBR Capital" },
				{ label: "Kia Finance", value: "Kia Finance" },
				{ label: "Lendable", value: "Lendable" },
				{ label: "Lombard Asset Finance", value: "Lombard Asset Finance" },
				{ label: "Mallard Finance", value: "Mallard Finance" },
				{ label: "Mann Island Finance", value: "Mann Island Finance" },
				{ label: "Marsh Finance", value: "Marsh Finance" },
				{ label: "Mercedes-Benz Financial Services", value: "Mercedes-Benz Financial Services" },
				{ label: "Mobilize", value: "Mobilize" },
				{ label: "Moneybarn", value: "Moneybarn" },
				{ label: "Moneyway", value: "Moneyway" },
				{ label: "MotoNovo", value: "MotoNovo" },
				{ label: "MotorKitty", value: "MotorKitty" },
				{ label: "Northridge", value: "Northridge" },
				{ label: "Novuna", value: "Novuna" },
				{ label: "Oodle Car Finance", value: "Oodle Car Finance" },
				{ label: "Oplo", value: "Oplo" },
				{ label: "Paragon", value: "Paragon" },
				{ label: "PCF Bank", value: "PCF Bank" },
				{ label: "PSA (Stellantis)", value: "PSA (Stellantis)" },
				{ label: "RCI (now Mobilize)", value: "RCI (now Mobilize)" },
				{ label: "Santander", value: "Santander" },
				{ label: "Shawbrook", value: "Shawbrook" },
				{ label: "Specialist Motor Finance", value: "Specialist Motor Finance" },
				{ label: "Startline Motor Finance", value: "Startline Motor Finance" },
				{ label: "Stellantis", value: "Stellantis" },
				{ label: "Tandem", value: "Tandem" },
				{ label: "Toyota Financial Services", value: "Toyota Financial Services" },
				{ label: "V12 Vehicle Finance", value: "V12 Vehicle Finance" },
				{ label: "Vauxhall Finance", value: "Vauxhall Finance" },
				{ label: "Volkswagen Financial Services", value: "Volkswagen Financial Services" },
				{ label: "Volvo Car Financial Services", value: "Volvo Car Financial Services" },
				{ label: "Zopa", value: "Zopa" },
			],
			form: {
				fullName: "",
				currentAddressAndPostCode: "",
				multipleAgreements: "no",
				multipleAgreementsForIndividualFinanceProvider: "no",
				individualFinanceProvider: "",
				multipleFinanceProviders: [],
				multipleFinanceProvidersFullDetails: [],
				policyNumber: "",
				licensePlateNumber: "",
				currentAddressSameAsWhenFinanceWasTaken: "yes",
				addressAtTimeOfFinanceAgreement: "",
				dateOfBirth: new Date(),
				totalAmountFinanced: 0,
				agreementInValidYearRange: "yes",
			},
			itemLabelPosition: "left",
			labelPosition: "left",
			isMobile: false,
		};
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.handleResize);
	},
	mounted() {
		this.handleResize();
		window.addEventListener("resize", this.handleResize);
	},
	methods: {
		clearSignature() {
			const signaturePad = this.$refs.signaturePad;
			signaturePad.clearSignature();
		},
		handleResize() {
			if (document.documentElement.clientWidth < 600) {
				this.isMobile = true;
				this.labelPosition = "top";
			} else if (document.documentElement.clientWidth > 1250) {
				this.isMobile = false;
				this.labelPosition = "left";
			}
		},
		handleChange(providers) {
			this.form.multipleFinanceProvidersFullDetails = providers.map((each) => {
				return {
					provider: each,
					multipleAgreementsForProvider: "no",
					knownPolicyNumbers: "",
					licensePlateNumbers: "",
					addressAtTimeOfAgreement: "",
					totalAmountFinanced: 0,
					agreementInValidYearRange: "yes",
				};
			});
		},
		generatePdf() {
			const form = this.form;

			let data = {
				fullName: form.fullName,
				dateOfBirth: form.dateOfBirth.toLocaleDateString(),
				currentAddressAndPostCode: form.currentAddressAndPostCode,
				customerHasMultipleFinanceAgreementsFromDifferentProviders: form.multipleAgreements,
				currentAddressSameAsWhenFinanceWasTaken: form.currentAddressSameAsWhenFinanceWasTaken,
			};

			if (form.multipleAgreements) {
				data.financePartners = form.multipleFinanceProvidersFullDetails;
			} else {
				data.financePartners = [
					{
						provider: form.individualFinanceProvider,
						multipleAgreementsForProvider: form.multipleAgreementsForIndividualFinanceProvider,
						knownPolicyNumbers: form.policyNumber,
						licensePlateNumbers: form.licensePlateNumber,
						addressAtTimeOfAgreement: form.addressAtTimeOfFinanceAgreement,
						totalAmountFinanced: form.totalAmountFinanced,
						agreementInValidYearRange: form.agreementInValidYearRange,
					},
				];
			}
			// PDF content
			const header = { text: "CLA Customer Finance Agreement Details", style: "header" };

			const basicDetails = [
				{ text: `Full Name: ${data.fullName}`, style: "subheader" },
				{ text: `Date of Birth: ${data.dateOfBirth}`, style: "subheader" },
				{ text: `Current Address: ${data.currentAddressAndPostCode}`, style: "subheader" },
				{
					text: `Has Multiple Finance Agreements: ${data.customerHasMultipleFinanceAgreementsFromDifferentProviders}`,
					style: "subheader",
				},
				{
					text: `Address Remains The Same From When Agreements Were Taken Out: ${data.currentAddressSameAsWhenFinanceWasTaken}`,
					style: "subheader",
				},
			];

			// Table for finance partners
			const financePartnersTable = {
				style: "table",
				table: {
					widths: ["*", "auto", "auto", "auto", "auto", "auto"],
					body: [
						[
							{ text: "Provider", style: "tableHeader" },
							{ text: "Multiple Agreements", style: "tableHeader" },
							{ text: "Known Policy Numbers", style: "tableHeader" },
							{ text: "License Plate Numbers", style: "tableHeader" },
							{ text: "Address at Time of Agreement", style: "tableHeader" },
							{ text: "Total Amount Financed", style: "tableHeader" },
						],
					],
				},
			};

			data.financePartners.forEach((partner) => {
				financePartnersTable.table.body.push([
					partner.provider,
					partner.multipleAgreementsForProvider,
					partner.knownPolicyNumbers,
					partner.licensePlateNumbers,
					partner.addressAtTimeOfAgreement,
					partner.totalAmountFinanced.toLocaleString() + " GBP",
				]);
			});

			const signaturePad = this.$refs.signaturePad;
			const signatureDataUrl = signaturePad.saveSignature();

			const docDefinition = {
				content: [
					{
						image: logo,
						width: 150,
						alignment: "center",
						marginBottom: 20,
					},
					header,
					...basicDetails,
					financePartnersTable,
					signatureDataUrl.data
						? ({ text: data.fullName + " Signature:", margin: [0, 20, 0, 5], style: "subheader" },
						  {
								text: `I hereby authorise CLA International FZE to act as my exclusive agent with respect to
								the submission and management of all complaints against the referenced auto finance
								companies for the miss-selling of automotive finance`,
								margin: [0, 20, 0, 5],
						  },
						  {
								image: signatureDataUrl.data,
								width: 200,
								height: 100,
						  })
						: {},
				],
				styles: {
					header: {
						fontSize: 18,
						bold: true,
						marginBottom: 10,
					},
					subheader: {
						fontSize: 14,
						marginBottom: 5,
					},
					table: {
						margin: [0, 5, 0, 15],
					},
					tableHeader: {
						bold: true,
						fontSize: 13,
						color: "black",
					},
				},
			};

			// Create and download the PDF
			generatePdfProxy.createPdf(docDefinition).download(`${data.fullName}_FinanceAgreementDetails.pdf`);
		},
	},
};
</script>

<style scoped>
.common-layout {
	height: 100%;
}

.formWrapper {
	padding: 20px;
	width: 100%;
	height: 100%;
}

.form {
	margin: auto;
	height: 100%;
}

h1 {
	margin-top: 60px;
}

.logo {
	height: 50px;
	margin-bottom: 50px;
	margin: auto;
	display: block;
}

#signature {
	border: double 3px transparent;
	border-radius: 5px;
	background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
	background-origin: border-box;
	background-clip: content-box, border-box;
}
</style>
